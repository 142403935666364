<!--
 * @description: 描述信息
 * @author: chenhua
 * @Date: 2024-01-23 15:57:30
 * @LastEditors: chenhua
 * @LastEditTime: 2024-01-24 10:05:06
-->
<template>
  <mu-card-header
    style="display: flex; align-items: center"
    :title="userinfo.username"
    :sub-title="userinfo.mobile"
  >
    <mu-avatar slot="avatar">
      <!-- <img :src="userinfo.userhead || defaultHead" /> -->
      <img src="https://app.bevzl.com/static/img/touxiang.9fd8f89c.png" />
    </mu-avatar>
    <div class="operate" v-if="operate">
      <div @click="options()">
        <img src="@/assets/image/option.png" alt="" />
      </div>
      <div @click="exits()">
        <img src="@/assets/image/exit.png" alt="" />
      </div>
    </div>
  </mu-card-header>
</template>
<script>
import head from "@/assets/image/headImage.png";
import store from "store";
export default {
  props: {
    operate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultHead: head,
      userinfo: {
        userhead: "",
        username: "",
        mobile: "",
      },
    };
  },
  methods: {
    options() {
      this.$emit("optionicon");
    },
    exits() {
      this.$emit("exiticon");
    },
  },
  mounted() {
    this.userinfo.username = store.get("adminuser").adminuser.realName;
    this.userinfo.userhead = store.get("adminuser").adminuser.headImage;
    this.userinfo.mobile = store.get("adminuser").adminuser.mobile;
  },
};
</script>
<style lang="scss" scoped>
.mu-card-title {
  color: #c7d5f2;
}

.mu-card-sub-title {
  color: #626a8f;
}

.mu-card-header {
  position: relative;

  .operate {
    position: absolute;
    right: 0;

    img {
      width: 0.4389rem;
      height: 0.4389rem;
    }

    div {
      display: inline-block;
      margin-left: 0.4083rem;
    }
  }
}
</style>
