/**
 * 接口域名的管理
 */
// 正式配置
// const base = {
//
//   //sq:'/api',//请求代理需要用到
//     sq:'http://wechat.nxptdn.com',
//     bd: 'http://operator.nxptdn.com',
//     client:'http://e.nxptdn.com',
//     appid:'wx9e5e67da26c51a14'
// }


//
const base = {
  sq: '/api',//请求代理需要用到
  //sq:'http://test-wechat.nxptdn.com',
  bd: "https://app.bevzl.com/operate",
  client: 'https://h5.bevzl.com',
  appid: 'wxc0db52167e547f73'
}
//测试环境
// const base = {
//   sq: '/api',//请求代理需要用到
//   //sq:'http://test-wechat.nxptdn.com',
//   // bd: 'http://charge-sit-api.nxptdn.com',
//   bd: 'http://localhost:9090/operate',
//   // bd: "https://app-api.hehaokeji.com/",
//   client: 'https://zlgzh.ubanquan.cn',
//   // appid: 'wx62eb4439eb634ca8'
//   appid: 'wxc0db52167e547f73'
// }


// const base = {
//     sq: '/api',
//     // sq:'http://202.85.220.170:8866/electric-website',   http://test-wechat.nxptdn.com/
//     bd: '/api'
// }


export default base;


